import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-132120220-2');

type RollResult = {
  result: "mango" | "papaya", 
  counts: { 
    mango: number, 
    papaya: number 
  }
}

const App = () => {
  const [roll, setRoll] = useState<RollResult>();
  const [clicks, setClicks] = useState(0);
  const [rolling, setRolling] = useState(false);
  const [route, setRoute] = useState("");

  const fader = () => <span className={`result-text fadeOut`}>{roll?.result}!</span>
  const faderPlaceholder = () => <span className={`result-text`} style={{color: "white"}}>rolling...</span>

  useEffect(() => {
    setRolling(true);

    axios.get("https://us-central1-mango-or-papaya.cloudfunctions.net/roll")
      .then(res => {
        setRoll(res.data as RollResult);
        setRolling(false);
        
        ReactGA.pageview(window.location.pathname + window.location.search);
      })
  }, [clicks]);

  return route !== "about" ? (
    <div className="container">
      <main>
        <div className="share-buttons"><div className="sharethis-inline-share-buttons"></div></div>
        <span onClick={() => setRoute("about")} className="info"><FontAwesomeIcon title="About" icon={faInfoCircle} size="2x" /></span>
        {!rolling ? fader() : faderPlaceholder()}
        {roll && <img src={`/${roll?.result}.png`} title={roll?.result} alt={roll?.result} width="75%" /> }
      </main>

      <footer>
        <div className="spin-counter"><img style={{ marginRight: "-3px" }} title="mango" alt="mangoes" src="/mango.png" width="24px" /><br />{roll?.counts.mango || <span>&infin;</span>}</div>
        <div onClick={() => setClicks(clicks + 1)} className="spin-button"><FontAwesomeIcon icon={faSync} size="2x" /></div>
        <div className="spin-counter"><img src="/papaya.png" title="papaya" alt="papayas" width="24px" /><br />{roll?.counts.papaya || <span>&infin;</span>}</div>
      </footer>
    </div>
  ) : (
    <div className="container">
      <main style={{ alignItems: "start" }}>
        <p>
          A few years ago, I was driving in Ireland and listening to BBC 1.<br />
          The DJ (does anyone know who?) mentioned that someone should just make a site that shows a mango or a papaya every time.<br />
          For some reason, that stuck with me but I never did anything with it.
        </p>
          <p>Now, in the midst of CORVID-19, all our friends are taking our board games virtual.</p>
          <p>And sometimes, you just need to flip a coin, but <strong>why flip a coin when you can roll a mango or a papaya?</strong></p>
          <p>Enjoy!</p>
        </main>

        <footer>
          <div><a href="https://www.linkedin.com/in/igorbelagorudsky/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a></div>
          <div onClick={() => setRoute("")} className="spin-button"><FontAwesomeIcon icon={faSync} size="2x" /></div>
          <div><a href="https://www.instagram.com/lookattheigor" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} size="2x" /></a></div>
        </footer>
      </div>
  )
}

export default App;